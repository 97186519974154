exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[slug]-js": () => import("./../../../src/pages/[slug].js" /* webpackChunkName: "component---src-pages-[slug]-js" */),
  "component---src-pages-admin-etudiant-[id]-js": () => import("./../../../src/pages/admin/etudiant/[id].js" /* webpackChunkName: "component---src-pages-admin-etudiant-[id]-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-inscription-js": () => import("./../../../src/pages/admin/inscription.js" /* webpackChunkName: "component---src-pages-admin-inscription-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admission-js": () => import("./../../../src/pages/admission.js" /* webpackChunkName: "component---src-pages-admission-js" */),
  "component---src-pages-connexion-js": () => import("./../../../src/pages/connexion.js" /* webpackChunkName: "component---src-pages-connexion-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ecole-js": () => import("./../../../src/pages/ecole.js" /* webpackChunkName: "component---src-pages-ecole-js" */),
  "component---src-pages-formation-js": () => import("./../../../src/pages/formation.js" /* webpackChunkName: "component---src-pages-formation-js" */),
  "component---src-pages-formations-[slug]-js": () => import("./../../../src/pages/formations/[slug].js" /* webpackChunkName: "component---src-pages-formations-[slug]-js" */),
  "component---src-pages-formulaire-js": () => import("./../../../src/pages/formulaire.js" /* webpackChunkName: "component---src-pages-formulaire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vie-etudiante-js": () => import("./../../../src/pages/vie-etudiante.js" /* webpackChunkName: "component---src-pages-vie-etudiante-js" */)
}

